// extracted by mini-css-extract-plugin
export var container = "Carousel-module--container--xnN16";
export var control = "Carousel-module--control--bTAFC";
export var controlIcon = "Carousel-module--control-icon--O9Mpc";
export var controlNext = "Carousel-module--control-next--oWMlr";
export var controlPrev = "Carousel-module--control-prev--ikWqG";
export var controls = "Carousel-module--controls--mYPE7";
export var icon = "Carousel-module--icon--5ErGI";
export var item = "Carousel-module--item--QCY4z";
export var itemContainer = "Carousel-module--item-container--nWyqY";
export var itemDescription = "Carousel-module--item-description--CI1BQ";
export var itemHeadline = "Carousel-module--item-headline--W1-lB";
export var itemLink = "Carousel-module--item-link--M4ubh";
export var itemMedia = "Carousel-module--item-media--2OIQp";
export var itemMediaImg = "Carousel-module--item-media-img--pn+0M";
export var itemOverlay = "Carousel-module--item-overlay--3Sfh3";
export var itemOverlayLg = "Carousel-module--item-overlay-lg--QuHww";
export var itemOverlaySm = "Carousel-module--item-overlay-sm--hRYVf";
export var itemSubheadline = "Carousel-module--item-subheadline---Rpq+";
export var itemVideo = "Carousel-module--item-video--umVd6";
export var itemWrapper = "Carousel-module--item-wrapper--Fnwkj";
export var items = "Carousel-module--items----LvW";
export var navButton = "Carousel-module--nav-button--1suz7";
export var navButtonLabel = "Carousel-module--nav-button-label--fFClW";
export var navControl = "Carousel-module--nav-control--VY9y4";
export var navIcon = "Carousel-module--nav-icon--HX3iq";
export var navItem = "Carousel-module--nav-item--OWa5z";
export var navWrapper = "Carousel-module--nav-wrapper--erKjx";
export var title = "Carousel-module--title--ZWIOF";
export var track = "Carousel-module--track--9MlC6";
export var wrapper = "Carousel-module--wrapper--hM9O+";