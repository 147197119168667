import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { canUseDOM } from 'exenv';
import ReactPlayer from 'react-player/lazy';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import CarouselVideoOverlayButton from './CarouselVideoOverlayButton';
import { Button } from '../ui/button/button';
import * as style from './Carousel.module.scss';
// import overlaySm from 'src/assets/media/carousel-overlay-sm.png';
// import overlayLg from 'src/assets/media/carousel-overlay-lg-2.png';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

// const overlays: types.SizeFields<string> = {
//   sm: overlaySm,
//   lg: overlayLg,
// };

// types
export type Props = {
  index: number;
  activeSlide: number;
  data: CarouselItemType;
  pausedByButton: boolean;

  handlePlayNext: () => void;
  handlePlaying: (index: number) => boolean;
  setVideoStatus: React.Dispatch<React.SetStateAction<types.MediaState>>;
  setAutoplay?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CarouselItemType = {
  id: string;
  button: types.ButtonFields;
  headline: types.TextFields;
  subheadline: types.TextFields;
  releaseDate?: string;
  releaseDateSubHeadline?: string;
  description: types.TextFields;
  image?: types.ImageFields;
  video?: types.VideoFields;
  overlayShadow?: types.ImageFields;
};

// carousel item
export const CarouselItem: React.FC<Props> = ({
  index,
  activeSlide,
  data,
  pausedByButton,

  setVideoStatus,
  setAutoplay,
  handlePlayNext,
  handlePlaying,
}) => {
  // states
  const [videoOverlayOpen, setVideoOverlayOpen] = React.useState(false);

  // transform 'releaseDate' to date object
  const dateRelease = moment(data.releaseDate);

  // title data
  const subheadlineText = dateRelease.isValid()
    ? !dateRelease.isAfter()
      ? data.releaseDateSubHeadline || 'AVAILABLE NOW'
      : data.subheadline?.text.replace('{date}', dateRelease.format('M/D')) ||
        `PREMIERES ${dateRelease.format('M/D')}`
    : data.subheadline?.text || ' ';

  // hooks
  const view = useView();

  // refs
  const videoRefs = React.useRef<ReactPlayer[]>([]);

  const setVideoRef = (index: number) => (ref: ReactPlayer | null) => {
    if (!ref) return;
    videoRefs.current[index] = ref;
  };

  React.useEffect(() => {
    if (activeSlide !== index) return;

    if (!pausedByButton && videoOverlayOpen) {
      setVideoStatus(types.MediaStates.stop);
    }

    if (!pausedByButton && !videoOverlayOpen) {
      setVideoStatus(types.MediaStates.play);
    }
  }, [activeSlide, index, pausedByButton, videoOverlayOpen, setVideoStatus]);

  // Don't render component when loaded on server as this relies on `window`.
  if (!canUseDOM) {
    return null;
  }

  // render
  return (
    <li
      className={clsx(style.item, {
        'is-active': index === activeSlide,
      })}
      aria-hidden={index !== activeSlide}>
      <div className={clsx(style.itemMedia)}>
        {/* background image */}
        {data.image && !data.video && (
          <img
            className={clsx(style.itemMediaImg)}
            src={getStrapiImageSource(
              data.image.src[view] as types.ImageSource
            )}
            alt={data.image.alt}
            loading="lazy"
          />
        )}

        {/* background video */}
        {data.video && (
          <ReactPlayer
            ref={setVideoRef(index)}
            muted
            className={clsx(style.itemVideo)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            url={getStrapiImageSource(data.video.src[view] as any)}
            width="100%"
            height="100%"
            playsinline={true}
            loop={true}
            playing={handlePlaying(index)}
            config={{
              file: {
                attributes: {
                  poster: data.video.poster,
                },
              },
              youtube: {
                playerVars: {
                  enablejsapi: 1,
                  noCookie: true,
                },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com',
                },
              },
            }}
            onEnded={handlePlayNext}
          />
        )}
      </div>

      {/* shadow overlay */}
      <div
        className={clsx({
          [style.itemOverlaySm]: view === types.Sizes.sm,
          [style.itemOverlayLg]: view === types.Sizes.lg,
        })}
        // style={{
        //   backgroundImage: data.overlayShadow
        //     ? `url(${data.overlayShadow.src[view]})`
        //     : `url(${overlays[view]})`,
        // }}
      />

      {/* content */}
      <article className={clsx(style.itemContainer)}>
        <div className={clsx(style.itemWrapper)}>
          {/* content headline */}
          <h2 className={clsx(style.itemHeadline)} style={data.headline.style}>
            {data.headline.text}
          </h2>

          {/* content subheadline */}
          <h3
            className={clsx(style.itemSubheadline)}
            style={data.subheadline.style}>
            {subheadlineText}
          </h3>

          {/* content description */}
          <p
            className={clsx(style.itemDescription)}
            style={data.description.style}>
            {data.description.text}
          </p>

          {/* content button */}
          {data.button == null ? (
            <></>
          ) : data.button.type === 'overlay' ? (
            <CarouselVideoOverlayButton
              buttonTitle={data.button.text}
              videoTitle={data.button.overlayTitle ?? ''}
              videoURL={data.button.link}
              buttonStyle={data.button.style}
              videoOverlayOpen={videoOverlayOpen}
              setVideoOverlayOpen={setVideoOverlayOpen}
            />
          ) : (
            <Button
              tag="a"
              href={data.button.link}
              label={data.button.text}
              type={data.button.type}
              icon={['far', 'play-circle']}
              style={data.button.style}
            />
          )}
        </div>
      </article>
    </li>
  );
};
